import { useDispatch } from "react-redux";
import { setEditMode } from "./store/EditorSlice";

const ExitEditorModal = () => {
    const dispatch = useDispatch();

    return (
        <div
            className="modal fade"
            id={`exitEditorModal`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exitEditorModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exitEditorModalLabel">
                            Exit Editor
                        </h5>
                    </div>
                    <div className="modal-body">
                        Are you sure you want to exit? Any unsaved changes will be lost.
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-warning"
                            data-dismiss="modal"
                            onClick={() => dispatch(setEditMode(false))}
                        >
                            Yes
                        </button>
                        <button

                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExitEditorModal;