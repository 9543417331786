import { useSelector, useDispatch } from "react-redux";
import { selectActivePoint, editActivePoint, selectMatch } from "../store/EditorSlice";
import ButtonGroup from "../../../components/ButtonGroup";
import { shotLocationTypeOptions } from "../options";
import { servicePlacementTypeOptions, shotSpeedOptions } from "../options";
import { convertKMHtoMPH, convertMPHtoKMH } from "../../../utils/app-utils";

const EditShot = ({ shot, index }) => {
    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);

    let shotPlacementValue = (!shot.out && !shot.net) ? 0 : shot.out ? 1 : 2;

    const optionChangedHandler = (field, value) => {
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const shotPlacementChangedHandler = (field, value) => {
        const updatedPoint = { ...activePoint };
        const updatedShot = { ...shot };
        switch (value) {
            case 0:
                updatedShot.out = false;
                updatedShot.net = false;
                break;
            case 1:
                updatedShot.out = true;
                updatedShot.net = false;
                break;
            case 2:
                updatedShot.out = false;
                updatedShot.net = true;
                break;
            default:
                break;

        }
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const speedChangedHandler = (field, value) => {
        const updatedShot = { ...shot, speed: match.speedUnit == 1 ? convertMPHtoKMH(value) : value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    var title = shot.shotOrderId == 0 ? "First Serve" : shot.shotOrderId == 1 ? "Second Serve" : "Return";

    return (
        <>
            <span>{title}</span>
            <div className="mb-1">
                <ButtonGroup value={shotPlacementValue} options={servicePlacementTypeOptions} onOptionChanged={shotPlacementChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
            <ButtonGroup  value={match.speedUnit == 1 ? Math.round(convertKMHtoMPH(shot.speed)) : shot.speed} options={shotSpeedOptions} onOptionChanged={speedChangedHandler} />
        </>
    );
}

export default EditShot