import React from "react";

function ButtonGroup({ options, value, field, onOptionChanged }) {
  return (
    <div className="btn-group" role="group" aria-label="Basic example">
      {options.map((option) => (
        <button
          key={`${field}-${option.value}-button`}
          id={`${field}-${option.value}-button`}
          type="button"
          onClick={() => onOptionChanged(field, option.value)}
          className={`btn btn-outline-primary btn-sm ${option.value === value ? "bg-primary text-white" : ""}`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}

export default React.memo(ButtonGroup);
