import { useDispatch, useSelector } from "react-redux";
import { selectLastScoresSequence, setLastScoresSequence, setMatch, selectMatch } from "./store/EditorSlice";
import { updatePointScore } from "./EditorUtils";

const ResetMatchScores = () => {
    const dispatch = useDispatch();
    const lastScoresSequence = useSelector(selectLastScoresSequence);
    const match = useSelector(selectMatch);

    const resetMatchScores = () => {
        const updatedMatch = { ...match };
        updatedMatch.points = [...updatedMatch.points];

        for (let i = 0; i < updatedMatch.points.length; i++) {
            updatedMatch.points[i] = updatePointScore(updatedMatch.points[i], lastScoresSequence[i], updatedMatch);
        }

        dispatch(setLastScoresSequence(null));
        dispatch(setMatch(updatedMatch));
    };

    return (
        <button className="btn btn-sm btn-danger ml-2" onClick={resetMatchScores} disabled={!lastScoresSequence}>Reset Match Scores</button>
    );
}

export default ResetMatchScores;