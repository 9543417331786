import { useDispatch } from "react-redux";
import { setActivePointWithStep, setIsPlaying, setIsPlayingFromEnd, triggerReplay } from "../store/EditorSlice";

const NavigationControls = () => {
    const dispatch = useDispatch();
    return (
        <>
            <button
                className="btn btn-outline-primary btn-sm mx-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="previous"
                onClick={() => {
                    dispatch(setActivePointWithStep({ step: -1 }));
                }}
            >
                <span className="mx-2 fas fa-step-backward"></span>
            </button>
            <button
                className="btn btn-outline-primary btn-sm mx-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="replay from start"
                onClick={() => {
                    dispatch(setIsPlayingFromEnd(false));
                    dispatch(triggerReplay());
                }}
            >
                <span className="mx-2 fas fa-redo-alt"></span>
            </button>
            <button
                className="btn btn-outline-primary btn-sm mx-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="replay last 3 seconds"
                onClick={() => {
                    dispatch(setIsPlayingFromEnd(true));
                    dispatch(setIsPlaying(true));
                }}
            >
                <span className="mx-2 fas fa-reply"></span>
            </button>
            <button
                className="btn btn-outline-primary btn-sm mx-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="next"
                onClick={() => {
                    dispatch(setActivePointWithStep({ step: 1 }));
                }}
            >
                <span className="mx-2 fas fa-step-forward"></span>
            </button>
        </>
    );
}

export default NavigationControls;