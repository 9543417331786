import StandardReports from './standardReports/StandardReports';
import HEReports from './heReports/HEReports';
import AnalystCardReports from './analystCardReports/AnalystCardReports';

const Reports = ({ reportExecutionRecord, targetLocalName, isUserReport, clientSettings, reportEmailContent, isGeneratingEmail, generateEmailText }) => {
    return (
        <div>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#standard-reports" role="tab" aria-controls="standard-reports" aria-selected="true">Standard Reports ({reportExecutionRecord.stdReports.length})</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#he-reports" role="tab" aria-controls="he-reports" aria-selected="false">HE Reports ({reportExecutionRecord.heReports.length})</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#analyst-reports" role="tab" aria-controls="analyst-reports" aria-selected="false">Analyst Cards ({reportExecutionRecord.analystCardReports.length})</a>
                </li>
            </ul>
            <div className="tab-content p-3" >
                <div id="standard-reports" className="tab-pane fade show active">
                    <StandardReports stdReports={reportExecutionRecord.stdReports} reportExecutionId={reportExecutionRecord.reportExecutionRecordId} targetLocalName={targetLocalName} isUserReport={isUserReport} clientSettings={clientSettings} reportEmailContent={reportEmailContent} isGeneratingEmail={isGeneratingEmail} generateEmailText={generateEmailText}/>

                </div>
                <div id="he-reports" className="tab-pane fade">
                    <HEReports heReports={reportExecutionRecord.heReports} reportExecutionId={reportExecutionRecord.reportExecutionRecordId} targetLocalName={targetLocalName} isUserReport={isUserReport} clientSettings={clientSettings} />

                </div>
                <div id="analyst-reports" className="tab-pane fade">
                    <AnalystCardReports stdReports={reportExecutionRecord.stdReports} heReports={reportExecutionRecord.heReports} analystCardReports={reportExecutionRecord.analystCardReports} reportExecutionId={reportExecutionRecord.reportExecutionRecordId} targetLocalName={targetLocalName} isUserReport={isUserReport} />

                </div>
            </div>
        </div>
    );

}

export default Reports;