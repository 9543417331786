import { useState } from "react";
import NewAnalystCardReport from "./NewAnalystCardReport";
import AnalystCardReportsList from "./AnalystCardReportsList";
import DeleteReportModal from "../DeleteReportModal";

const AnalystCardReports = ({ stdReports, heReports, analystCardReports, reportExecutionId, targetLocalName, isUserReport }) => {
    const [selectedReport, setSelectedReport] = useState(null);
    return (
        <div style={{ width: "100%", overflowX: "hidden" }} className="mb-3">
            <div className="d-flex row align-items-center">
                <button
                    className="btn btn-primary fs--1 ml-4 mb-2"

                    type="button"
                    onClick={() => setSelectedReport(null)}
                    data-toggle="modal"
                    data-target="#AddNewAnalystCardReportModal">
                    Create New Report
                    <i className="fas fa-plus-circle ml-2"></i>
                </button>
                <NewAnalystCardReport reportExecutionId={reportExecutionId} stdReports={stdReports} heReports={heReports} editingReport={selectedReport} targetLocalName={targetLocalName} isUserReport={isUserReport} />
                <DeleteReportModal reportExecutionId={reportExecutionId} reportId={selectedReport?.reportId} reportType="analyst-report" />
            </div>
            <div
                className="collapse show"
                id="collapse-analystcard-reports"
                aria-labelledby="collapse-analystcard-reports"
            >
                <AnalystCardReportsList reportExecutionId={reportExecutionId} analystCardReports={analystCardReports} setSelectedReport={setSelectedReport} />
            </div>
        </div>
    );
};
export default AnalystCardReports;
