import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";
import { convertTimeStringToSeconds } from "../../utils/app-utils";
import { transformMatchPoint } from "../editor/EditorUtils";

export const snapshotsSlice = createApi({
    reducerPath: "snapshotsapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["CvMatches"],
    endpoints: (builder) => ({
        deleteCvSnapshot: builder.mutation({
            query: ({ videoProcessId, snapshot }) => ({
                url: `/videos/${videoProcessId}/snapshots/${snapshot}`,
                method: "DELETE",
            }),
        }),
        getMatchSnapshots: builder.query({
            query: (videoProcessId) => `/videos/${videoProcessId}/snapshots`,
            providesTags: (result, error, videoProcessId) => {
                return ["CvMatches", { type: "CvMatches", videoProcessId }];
            },
        }),
        postCvMatchSnapshot: builder.mutation({
            query: ({ videoProcessId, match }) => ({
                url: `/videos/${videoProcessId}/snapshots`,
                method: "POST",
                body: match,
            }),
            invalidatesTags: (result, error, args) => [{ type: "CvMatches", blobDir: args.blobDir }],
        }),
        getCvMatch: builder.query({
            query: ({ videoProcessId, snapshot }) => `/videos/${videoProcessId}/snapshots/${snapshot}`,
            providesTags: (result, error, args) => {
                return ["CvMatches", { type: "CvMatches", videoProcessId: args.videoProcessId, snapshot: args.snapshot }];
            },
            extraOptions: { maxRetries: 3 },
            transformResponse: (response) => {
                response.points = response.points.map((p) => transformMatchPoint(p, response));
                return response;
            }
        }),
        updateSnapshotPoint: builder.mutation({
            query: ({ videoProcessId, snapshot, point }) => ({
                url: `/videos/${videoProcessId}/snapshots/${snapshot}/points/${point.id}`,
                method: "PUT",
                body: point,
            }),
        }),
    }),
});

export const {
    useDeleteCvSnapshotMutation,
    useGetMatchSnapshotsQuery,
    usePostCvMatchSnapshotMutation,
    useGetCvMatchQuery,
    useUpdateSnapshotPointMutation,
} = snapshotsSlice;
