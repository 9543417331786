
import { useSelector, useDispatch } from "react-redux";
import { selectActivePoint, editActivePoint, selectMatch } from "../store/EditorSlice";
import ButtonGroup from "../../../components/ButtonGroup";
import { serviceLocationTypeOptions, servicePlacementTypeOptions } from "../options";
import NumberInput from "../../../components/NumberInput";
import Switch from "../../../components/Switch";

import { addSecondServe, addReturn } from "../EditorUtils";

const EditServe = ({ shot, index }) => {
    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);

    const speedUnit = match.speedUnit == 1 ? "km/h" : "mph";

    let servePlacementValue = (!shot.out && !shot.net) ? 0 : shot.out ? 1 : 2;

    const isFirstServe = shot.shotOrderId == 0;
    const isSecondServe = shot.shotOrderId == 1;
    const isAce = activePoint.isAce && ((isFirstServe && activePoint.serveTypeId == 0) || isSecondServe);
    const label = isFirstServe ? "1st Serve" : "2nd Serve";


    const optionChangedHandler = (field, value) => {
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => i == index ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const updateServeType = (updatedShot, updatedPoint) => {
        servePlacementValue = (!updatedShot.out && !updatedShot.net) ? 0 : updatedShot.out ? 1 : 2;
        //first serve in
        if (isFirstServe && servePlacementValue === 0) {
            updatedPoint.serveTypeId = 0;
            updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId !== 1);
            if (!updatedPoint.isAce)
                addReturn(updatedPoint);
        }
        // first serve out
        else if (isFirstServe && servePlacementValue !== 0) {
            updatedPoint.serveTypeId = 1;
            addSecondServe(updatedPoint);
            if (!updatedPoint.isAce)
                addReturn(updatedPoint);
        }
        //second serve in
        else if (isSecondServe && servePlacementValue === 0) {
            updatedPoint.serveTypeId = 1;
            if (!updatedPoint.isAce)
                addReturn(updatedPoint);
        }
        //second serve out (double fault)
        else if (isSecondServe && servePlacementValue !== 0) {
            updatedPoint.serveTypeId = 2;
            updatedPoint.rallyLength = 0;
            updatedPoint.isAce = false;
            updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId === 0 || s.shotOrderId === 1);
        }
        dispatch(editActivePoint(updatedPoint));
    }

    const servePlacementChangedHandler = (field, value, newPoint) => {
        const updatedShot = { ...shot };
        switch (value) {
            case 0:
                updatedShot.out = false;
                updatedShot.net = false;
                break;
            case 1:
                updatedShot.out = true;
                updatedShot.net = false;
                break;
            case 2:
                updatedShot.out = false;
                updatedShot.net = true;
                break;
            default:
                break;

        }
        const updatedPoint = newPoint ?? { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => i == index ? updatedShot : s);
        updateServeType(updatedShot, updatedPoint);
    }

    const aceChangedHandler = (field, value) => {
        const updatedPoint = { ...activePoint, isAce: value };
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId === 0 || s.shotOrderId === 1);
        if (value) {
            updatedPoint.rallyLength = 1;
            servePlacementChangedHandler("servePlacement", 0, updatedPoint);
        } else {
            addReturn(updatedPoint);
            dispatch(editActivePoint(updatedPoint));
        }
    }

    return (
        <div className="d-flex flex-column mr-1 max-w-50">
            <div className="d-flex align-items-center justify-content-between mb-1">
                <span>{label}</span>
                <div className="d-flex align-items-center">
                    <span className="mr-1">Ace</span>
                    <Switch field={`ace-${shot.shotOrderId}`} value={isAce} onChanged={aceChangedHandler} />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center mb-1">
                <NumberInput field="speed" value={shot.speed} onNumberChanged={optionChangedHandler} />
                <span className="ml-1">{speedUnit}</span>
            </div>
            <div className="mb-1">
                <ButtonGroup value={servePlacementValue} options={servicePlacementTypeOptions} onOptionChanged={servePlacementChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="serviceLocationId" value={shot.serviceLocationId} options={serviceLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
        </div>
    );
}

export default EditServe;