import { useGetLocalEventInstancesQuery, useGetAllHEPlayersQuery } from "../../../features/api/completeMatchesSlice";
import { useCreateHEReportMutation } from "../../../features/api/reportExecutionSlice";
import { useEffect, useState } from "react";
import Select from "react-select";
import Spinner from "../../../UI/Spinner";
import HETrackingSheet from "./HETrackingSheet";
import { useGetHEReportMatchesCountMutation } from "../../../features/api/reportsSlice";
import { heInitialInputs, yearsOptions, typeOptions, categoryOptions } from "../reportsOptions";
import useDropboxPath from "../../../custom-hooks/useDropboxPath";
import useShowToastr from "../../../custom-hooks/useShowToastr";

const NewHEReport = ({ reportExecutionId, editingReport, targetLocalName, isUserReport, clientSettings }) => {

    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [playerOptions, setPlayerOptions] = useState([]);
    const [trackingSheet, setTrackingSheet] = useState(null);
    const [type, setType] = useState("Single");

    const [formInputs, setFormInputs] = useState(heInitialInputs);

    const invalidInputs = formInputs.selectedTargetPlayers.length === 0 || (formInputs.opponent === 3 && formInputs.selectedOpponentPlayers.length === 0);

    const { data: tournaments } = useGetLocalEventInstancesQuery();
    const { data: hePlayers } = useGetAllHEPlayersQuery();

    const [createHERecord, { isLoading: loadingReportCreation, isSuccess: createdSuccessfully, isError: creationError }] = useCreateHEReportMutation();

    const [getHEReportMatchesCount, { isLoading: loadingReportMatchesCount, isError: reportMatchesCountError }] = useGetHEReportMatchesCountMutation();

    const { userSettings, setUserSettings } = useDropboxPath({ isUserReport, container: "he-dropbox-container", setFormInputs });

    useShowToastr(reportMatchesCountError, "Failed to get matches count!", "Matches Count");

    useShowToastr({ isSuccess: createdSuccessfully, isError: creationError, successMsg: "Created Successfully!", errorMsg: "Failed to create!", title: "Create New Report", modalId: "AddNewHEReportModal" });

    useEffect(() => {
        if (clientSettings) {
            setFormInputs({ ...formInputs, targetSpeedUnit: clientSettings.defaultSpeedUnit });
        }
    }, [clientSettings])

    useEffect(() => {
        setTrackingSheet(null);
    }, [formInputs])

    useEffect(() => {
        if (editingReport) {
            setFormInputs({ ...editingReport.reportInputs });
            if (editingReport.reportInputs.hybridFromYear) { setType("Hybrid"); }
            else { setType("Single"); }
        } else {
            const newFormInputs = { ...heInitialInputs, dropboxOutputDir: userSettings?.defaultDropboxPath };
            const targetPlayer = hePlayers?.find(p => p.name.toLowerCase() === targetLocalName?.toLowerCase());

            if (targetPlayer) {
                newFormInputs.category = categoryOptions.find(c => c.label == targetPlayer.category).value;
                newFormInputs.selectedTargetPlayers = [targetPlayer.name];
            }

            setFormInputs(newFormInputs);
            setType("Single");
        }
    }, [editingReport]);

    useEffect(() => {
        if (tournaments) {
            setTournamentOptions(tournaments
                .filter(t => t.category === categoryOptions.find(c => c.value === formInputs.category).label)
                .map((tournament) => ({
                    label: `${tournament.name} - ${tournament.category}`,
                    value: tournament.name
                })));
        }
    }, [tournaments, formInputs.category]);

    useEffect(() => {
        if (hePlayers) {
            const targetPlayer = hePlayers.find(p => p.name.toLowerCase() === targetLocalName?.toLowerCase());
            var category = formInputs.category;

            if (targetPlayer) {
                category = categoryOptions.find(c => c.label == targetPlayer.category).value;
                setFormInputs({ ...formInputs, selectedTargetPlayers: [targetPlayer.name], category: category });
            } else {
                setFormInputs({ ...formInputs, selectedTargetPlayers: [] });
            }

            setPlayerOptions(hePlayers
                .filter(p => p.category === categoryOptions.find(c => c.value === category).label)
                .map((player) => ({
                    label: player.name,
                    value: player.name
                })));
        }
    }, [hePlayers, formInputs.category, targetLocalName]);

    const handleCreateReport = async () => {
        const payload = {
            ...formInputs,
            blobOutputDir: `report-automation/${reportExecutionId}/he-report`
        };

        await createHERecord({ reportInputsModel: payload, reportExecutionId: reportExecutionId, reportId: editingReport?.reportId });
    }

    const typeChangedHandler = (e) => {
        setType(e.target.value);
        if (e.target.value === "Single")
            setFormInputs({ ...formInputs, hybridFromYear: null, hybridToYear: null, hybridIsHardSelected: false, hybridIsClaySelected: false, hybridIsGrassSelected: false });
        else
            setFormInputs({ ...formInputs, hybridFromYear: new Date().getFullYear(), hybridToYear: new Date().getFullYear(), hybridIsHardSelected: true, hybridIsClaySelected: true, hybridIsGrassSelected: true });
    }

    const matchesCountHandler = async () => {
        var response = await getHEReportMatchesCount(formInputs);
        if (response)
            setTrackingSheet(response.data);
    }

    return (
        <div
            className="modal fade"
            id="AddNewHEReportModal"
            role="dialog"
            aria-labelledby="AddNewHEReportModalLabel"
            aria-hidden="true"
        >
            <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewHEReportModalLabel">
                            {editingReport ? "Edit HE Report" : "Create New HE Report"}
                        </h3>
                    </div>
                    <div style={{ height: "75vh" }} className="modal-body scrollbar perfect-scrollbar">
                        <div className="container d-flex flex-column mb-2">
                            <div className="form-group">
                                <label htmlFor="category">Category</label>
                                <select
                                    style={{ width: "30%" }}
                                    className="form-control mb-3"
                                    id="category"
                                    value={formInputs.category}
                                    onChange={(e) => setFormInputs({ ...formInputs, category: parseInt(e.target.value), selectedTargetPlayers: [], selectedOpponents: [], selectedTournaments: [] })}>
                                    {categoryOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <div className="d-flex flex-column mb-2">
                                    <label >Target Player</label>
                                    <div className="row align-items-center ml-0">
                                        {playerOptions.length === 0 ? <Spinner /> :
                                            <div style={{ width: "30%" }} className="mr-3">
                                                <Select
                                                    className="dark-theme"
                                                    value={formInputs.selectedTargetPlayers.length > 0 ? playerOptions.find(p => p.value.toUpperCase() === formInputs.selectedTargetPlayers[0].toUpperCase()) : null}
                                                    options={playerOptions}
                                                    onChange={(selectedOption) => setFormInputs({ ...formInputs, selectedTargetPlayers: [selectedOption.value] })} />

                                            </div>}
                                        <input
                                            type="checkbox"
                                            id="isLeftHanded"
                                            checked={formInputs.isLeftHanded}
                                            onChange={(e) => setFormInputs({ ...formInputs, isLeftHanded: e.target.checked })} />
                                        <label className="ml-2 mb-0" htmlFor="isLeftHanded">Is Target Left-Handed</label>
                                    </div>
                                </div>
                                <div style={{ width: "30%" }} className="d-flex flex-column">
                                    <label >Opponent Name</label>
                                    {playerOptions.length === 0 ? <Spinner /> : <Select
                                        className="dark-theme"
                                        value={formInputs.selectedOpponents.length > 0 ? playerOptions.find(p => p.value.toUpperCase() === formInputs.selectedOpponents[0].toUpperCase()) : { label: "All Players", value: "" }}
                                        options={[{ label: "All Players", value: "" }, ...playerOptions]}
                                        onChange={(selectedOption) => setFormInputs({ ...formInputs, selectedOpponents: selectedOption.value === "" ? [] : [selectedOption.value] })} />}
                                </div>
                                <div style={{ width: "70%" }} className="d-flex flex-column mt-3">
                                    <label htmlFor="tournaments">Tournaments</label>
                                    {tournamentOptions.length === 0 ? <Spinner /> : <Select
                                        className="dark-theme"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={tournamentOptions}
                                        value={formInputs.selectedTournaments.length > 0 ? tournamentOptions.filter(t => formInputs.selectedTournaments.includes(t.value)) : []}
                                        onChange={(selectedOptions) => setFormInputs({ ...formInputs, selectedTournaments: selectedOptions.map((option) => option.value) })} />}
                                </div>
                                <div style={{ width: "30%" }} className="d-flex flex-column mt-3">
                                    <label htmlFor="targetSpeedUnit">Target Speed Unit</label>
                                    <select className="form-control" id="targetSpeedUnit"
                                        value={formInputs.targetSpeedUnit}
                                        onChange={(e) => setFormInputs({ ...formInputs, targetSpeedUnit: e.target.value })}>
                                        <option value="MPH">MPH</option>
                                        <option value="KMH">KMH</option>
                                    </select>
                                </div>
                                <label className="mt-2">Type</label>
                                <select
                                    style={{ width: "30%" }}
                                    className="form-control mb-2"
                                    value={type}
                                    onChange={typeChangedHandler}>
                                    {typeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <div className="row ml-0">
                                    <div style={{ width: "30%" }} className="d-flex flex-column mr-4">
                                        <label htmlFor="fromYear">From</label>
                                        <select
                                            className="form-control mb-1"
                                            id="fromYear"
                                            value={formInputs.fromYear}
                                            onChange={(e) => setFormInputs({ ...formInputs, fromYear: parseInt(e.target.value) })}
                                        >
                                            {yearsOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ width: "30%" }} className="d-flex flex-column">
                                        <label htmlFor="toYear">To</label>
                                        <select
                                            className="form-control mb-1"
                                            id="toYear"
                                            value={formInputs.toYear}
                                            onChange={(e) => setFormInputs({ ...formInputs, toYear: parseInt(e.target.value) })}>
                                            {yearsOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div style={{ width: "70%" }} className="d-flex flex-column mt-3">
                                    <label htmlFor="surface">Surfaces</label>
                                    <div className="row ml-0">
                                        <input
                                            type="checkbox"
                                            id="isHardSelected"
                                            checked={formInputs.isHardSelected}
                                            onChange={(e) => setFormInputs({ ...formInputs, isHardSelected: e.target.checked })} />
                                        <label className="ml-2 mr-4 mb-0" htmlFor="isClaySelected">Hard</label>
                                        <input
                                            type="checkbox"
                                            id="isClaySelected"
                                            checked={formInputs.isClaySelected}
                                            onChange={(e) => setFormInputs({ ...formInputs, isClaySelected: e.target.checked })}
                                        />
                                        <label className="ml-2 mr-4 mb-0" htmlFor="isClaySelected">Clay</label>
                                        <input
                                            type="checkbox"
                                            id="isGrassSelected"
                                            checked={formInputs.isGrassSelected}
                                            onChange={(e) => setFormInputs({ ...formInputs, isGrassSelected: e.target.checked })} />
                                        <label className="ml-2 mr-4 mb-0" htmlFor="isGrassSelected">Grass</label>
                                    </div>
                                </div>
                                {
                                    type == "Hybrid" &&
                                    <div className="mt-4">
                                        <div className="row ml-0">
                                            <div style={{ width: "30%" }} className="d-flex flex-column mr-4">
                                                <label htmlFor="hybridFromYear">From</label>
                                                <select
                                                    className="form-control mb-1"
                                                    id="hybridFromYear"
                                                    value={formInputs.hybridFromYear}
                                                    onChange={(e) => setFormInputs({ ...formInputs, hybridFromYear: parseInt(e.target.value) })}
                                                >
                                                    {yearsOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div style={{ width: "30%" }} className="d-flex flex-column">
                                                <label htmlFor="hybridToYear">To</label>
                                                <select
                                                    className="form-control mb-1"
                                                    id="hybridToYear"
                                                    value={formInputs.hybridToYear}
                                                    onChange={(e) => setFormInputs({ ...formInputs, hybridToYear: parseInt(e.target.value) })}>
                                                    {yearsOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ width: "70%" }} className="d-flex flex-column mt-3">
                                            <label htmlFor="surface">Surfaces</label>
                                            <div className="row ml-0">
                                                <input
                                                    type="checkbox"
                                                    id="hybridIsHardSelected"
                                                    checked={formInputs.hybridIsHardSelected}
                                                    onChange={(e) => setFormInputs({ ...formInputs, hybridIsHardSelected: e.target.checked })} />
                                                <label className="ml-2 mr-4 mb-0" htmlFor="hybridIsHardSelected">Hard</label>
                                                <input
                                                    type="checkbox"
                                                    id="hybridIsClaySelected"
                                                    checked={formInputs.hybridIsClaySelected}
                                                    onChange={(e) => setFormInputs({ ...formInputs, hybridIsClaySelected: e.target.checked })}
                                                />
                                                <label className="ml-2 mr-4 mb-0" htmlFor="hybridIsClaySelected">Clay</label>
                                                <input
                                                    type="checkbox"
                                                    id="hybridIsGrassSelected"
                                                    checked={formInputs.hybridIsGrassSelected}
                                                    onChange={(e) => setFormInputs({ ...formInputs, hybridIsGrassSelected: e.target.checked })} />
                                                <label className="ml-2 mr-4 mb-0" htmlFor="hybridIsGrassSelected">Grass</label>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                            {
                                isUserReport &&
                                <div className="mt-3">
                                    <label className="fs-0 mb-0 mr-2">Upload to dropbox </label>
                                    <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadHEReportByDefault: !prev.uploadHEReportByDefault }))}>
                                        <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadHEReportByDefault}></input>
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            }
                            {isUserReport && userSettings.uploadHEReportByDefault && <div style={{ width: "70%" }} className="d-flex row flex-nowrap mt-3 align-items-center ml-0">
                                <div id="he-dropbox-container" />
                                <input style={{ width: "70%" }} readOnly value={formInputs.dropboxOutputDir} className="form-control ml-2 p-0 pl-2" />
                            </div>}

                            <button style={{ width: "20%" }} className="btn btn-primary btn-md mt-2 mb-0 ml-auto" type="button" disabled={loadingReportMatchesCount || invalidInputs} onClick={matchesCountHandler}> {loadingReportMatchesCount ? "Loading" : "Matches Count"}</button>
                            {trackingSheet && <HETrackingSheet title="Scouting" matches={trackingSheet.scoutingMatches} />}
                            {trackingSheet && <HETrackingSheet title="Matchup" matches={trackingSheet.matchupMatches} />}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <button className="btn btn-warning btn-md" type="button"
                            onClick={handleCreateReport}
                            disabled={loadingReportCreation || invalidInputs}>
                            {!editingReport ? (loadingReportCreation ? "Creating..." : "Create Report") : (loadingReportCreation ? "Editing..." : "Edit Report")}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NewHEReport;