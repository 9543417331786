import { useState } from "react";
import NewStandardReport from "./NewStandardReport";
import StandardReportsList from "./StandardReportsList";
import DeleteReportModal from "../DeleteReportModal";
import Spinner from "../../../UI/Spinner";
import Button from "../../../UI/Buttons/Button";
import parse from 'html-react-parser';

const StandardReports = ({ stdReports, reportExecutionId, targetLocalName, isUserReport, clientSettings, reportEmailContent, isGeneratingEmail, generateEmailText }) => {
  const [selectedReport, setSelectedReport] = useState(null);

  const CopyToClipboard = async (text) => {
    const blob = new Blob([text], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });
    await navigator.clipboard.write([clipboardItem]);
  }

  return (
    <div style={{ width: "100%", overflowX: "hidden" }} className="mb-3">
      <div className="d-flex row align-items-center">

        <button
          className="btn btn-primary fs--1 ml-4 mb-2"
          type="button"
          onClick={() => setSelectedReport(null)}
          data-toggle="modal"
          data-target="#AddNewStandardReportModal">
          Create New Report
          <i className="fas fa-plus-circle ml-2"></i>
        </button>
        <NewStandardReport reportExecutionId={reportExecutionId} editingReport={selectedReport} targetLocalName={targetLocalName} isUserReport={isUserReport} clientSettings={clientSettings} />
        <DeleteReportModal reportExecutionId={reportExecutionId} reportId={selectedReport?.reportId} reportType="standard-report" />
      </div>

      <StandardReportsList reportExecutionId={reportExecutionId} stdReports={stdReports} setSelectedReport={setSelectedReport} />
      {generateEmailText &&
        <div className="d-flex flex-column ml-0">
          <div className="row ml-0 my-2">
            <Button extraStyles={{ width: "fit-content", height: "fit-content" }} onClick={() => CopyToClipboard(reportEmailContent)} isDisabled={isGeneratingEmail || reportEmailContent == ""} >
              <i className="fas fa-copy mr-2"></i>
              {isGeneratingEmail ? "Generating Email..." : "Copy Email Content"}
            </Button>
            <Button extraStyles={{ width: "fit-content", height: "fit-content" }} onClick={generateEmailText} isDisabled={isGeneratingEmail}>
              <i className="fas fa-envelope mr-2"></i>
              Generate Email
            </Button>
          </div>
          <div className="card ml-auto scrollbar perfect-scrollbar vh-25 w-100">
            {isGeneratingEmail ? <Spinner /> : parse(reportEmailContent)}
          </div>
        </div>
      }
    </div>
  );
};
export default StandardReports;
