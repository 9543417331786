const serviceCourtOptions = [
    { value: 0, label: "Ad" },
    { value: 1, label: "Deuce" },
];

const serveTypeOptions = [
    { value: 0, label: "1st" },
    { value: 1, label: "2nd" },
    { value: 2, label: "Double Fault" },
];

const shotLocationTypeOptions = [
    { value: 0, label: "Line" },
    { value: 1, label: "Middle" },
    { value: 2, label: "Cross" },
];

const serviceLocationTypeOptions = [
    { value: 1, label: "Wide" },
    { value: 2, label: "Body" },
    { value: 3, label: "T" }
];

const servicePlacementTypeOptions = [
    { value: 0, label: "In" },
    { value: 1, label: "Out" },
    { value: 2, label: "Net" }
];

const numberOfSetsOptions = [
    { value: 1, label: "1" },
    { value: 3, label: "3" },
    { value: 5, label: "5" },
];

const speedUnitOptions = [
    { value: 0, label: "MPH" },
    { value: 1, label: "KMH" }
];

const typeOfScoringOptions = [
    { value: 1, label: "Ad" },
    { value: 2, label: "No-Ad" }
];

const matchFormatsOptions = [
    { value: "SingleSet", label: "Single Set" },
    { value: "BestOfThreeTiebreakSets", label: "Best Of Three Tiebreak Sets" },
    { value: "BestOfFiveTiebreakSets", label: "Best Of Five Tiebreak Sets" },
    { value: "BestOfThreeTenPointFinalSetTiebreak", label: "Best Of Three Ten Point Final Set Tiebreak" },
    { value: "BestOfFiveTenPointFinalSetTiebreak", label: "Best Of Five Ten Point Final Set Tiebreak" },
    { value: "BestOfThreeFastFourSets", label: "Best Of Three Fast Four Sets" },
    { value: "EightGameProSet", label: "Eight Game Pro Set" }
]

const tieBreakFinalSetOptions = [
    { value: 7, label: "7" },
    { value: 10, label: "10" }
];

const shotSpeedOptions = [
    { value: 55, label: "Slow" },
    { value: 65, label: "Average" },
    { value: 75, label: "Fast" }
];

export {
    serviceCourtOptions,
    serveTypeOptions,
    shotLocationTypeOptions,
    serviceLocationTypeOptions,
    servicePlacementTypeOptions,
    numberOfSetsOptions,
    speedUnitOptions,
    typeOfScoringOptions,
    matchFormatsOptions,
    tieBreakFinalSetOptions,
    shotSpeedOptions
};