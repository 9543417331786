const PathConstants = {
  INDEX: "/",
  LOGINCALLBACK: "/login/callback",
  EDITOR: "editor",
  EDITHE: "editHE",
  COLLECTEDVIDEOS: "collectedvideos",
  VIDEOSLINKING: "videoslinking",
  CODERSVIDEOSLINKING: "codersvideoslinking",
  DROPBOX: "dropbox",
  VIDEOJOBS: "videojobs",
  DRAWSREPORTS: "drawsreports",
  USERREPORTS: "userreports",
  PLAYERGROUPS: "playergroups",
  PLAYERLOOKUP: "playerlookup",
  TOURNAMENTLOOKUP: "tournamentlookup",
  SETTINGS: "settings",
  ONCPLAYERS: "oncplayers",
  ONCEVENTS: "oncevents",
  VIDEOCLIENTAPPSUMMARY: "videoClientAppSummary",
  EVENTS: "events",
  CVDASHBOARD: "cvdashboard",
  VIDEOSSEARCH: "videossearch",
  CLIENTSREPORTS: "clientsreports",
  CLIENTS: "clients",
  EDITOR_VIDEOS: "editorvideos"
};

export default PathConstants;
