import { LoginCallback } from '@okta/okta-react';
import { Route, Routes } from 'react-router-dom';
import PathConstants from "./pathConstants";

import Layout from '../Layout';
import CollectedVideosPage from '../features/collectedVideos/collectedVideosPage';
import MatchPlayer from '../features/editor/EditorPage';
import DrawsReportsPage from '../features/drawsReports/DrawsReportsPage';
import DropboxPage from '../features/dropbox/DropBoxPage';
import EditHEPage from '../features/editHE/EditHEPage';
import PlayerGroupsPage from '../features/playerGroup/PlayerGroupsPage';
import PlayerLookupPage from '../features/playerLookup/PlayerLookupPage';
import TournamentLookupPage from '../features/tournamentsLookup/TournamentLookupPage';
import UserReportsPage from '../features/userReports/UserReportsPage';
import VideoJobsPage from '../features/videoJobs/VideoJobsPage';
import VideosLinkingPage from '../features/videosLinking/VideosLinkingPage';
import VideosLinkingCodersPage from '../features/videosLinkingCoders/VideosLinkingCodersPage';
import IndexPage from '../pages/indexPage';
import SettingsPage from '../features/settings/settingsPage';
import ONCPlayersPage from '../features/onc/players/ONCPlayersPage';
import ONCEventsPage from '../features/onc/events/ONCEventsPage';
import VideoClientAppSummaryPage from '../features/operations/videoClientAppSummary/VideoClientAppSummaryPage';
import EventsPage from '../features/events/EventsPage';
import CVDashboardPage from '../features/cvDashboard/CVDashboardPage';
import VideosSearchPage from '../features/videosSearch/VideosSearchPage';
import ClientsReportPage from '../features/clientReports/ClientsReportPage';
import ClientsPage from '../features/clients/clientsPage';
import EditorVideosPage from '../features/editorVideos/EditorVideosPage';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path='' element={<IndexPage />} />
                <Route path={PathConstants.EDITOR} element={<MatchPlayer />} />
                <Route path={`${PathConstants.EDITOR}`} element={<MatchPlayer />} />
                <Route path={PathConstants.EDITHE} element={<EditHEPage />} />
                <Route path={`${PathConstants.COLLECTEDVIDEOS}/:category/:status`} element={<CollectedVideosPage />} />
                <Route path={`${PathConstants.VIDEOSLINKING}/:category`} element={<VideosLinkingPage />} />
                <Route path={`${PathConstants.CODERSVIDEOSLINKING}/:category`} element={<VideosLinkingCodersPage />} />
                <Route path={PathConstants.DROPBOX} element={<DropboxPage />} />
                <Route path={PathConstants.VIDEOJOBS} element={<VideoJobsPage />} />
                <Route path={PathConstants.PLAYERGROUPS} element={<PlayerGroupsPage />} />
                <Route path={PathConstants.DRAWSREPORTS} element={<DrawsReportsPage />} />
                <Route path={PathConstants.USERREPORTS} element={<UserReportsPage />} />
                <Route path={PathConstants.PLAYERLOOKUP} element={<PlayerLookupPage />} />
                <Route path={PathConstants.TOURNAMENTLOOKUP} element={<TournamentLookupPage />} />
                <Route path={PathConstants.SETTINGS} element={<SettingsPage />} />
                <Route path={PathConstants.ONCPLAYERS} element={<ONCPlayersPage />} />
                <Route path={PathConstants.ONCEVENTS} element={<ONCEventsPage />} />
                <Route path={PathConstants.VIDEOCLIENTAPPSUMMARY} element={<VideoClientAppSummaryPage />} />
                <Route path={PathConstants.EVENTS} element={<EventsPage />} />
                <Route path={PathConstants.CVDASHBOARD} element={<CVDashboardPage />} />
                <Route path={PathConstants.VIDEOSSEARCH} element={<VideosSearchPage />} />
                <Route path={PathConstants.CLIENTSREPORTS} element={<ClientsReportPage />} />
                <Route path={PathConstants.CLIENTS} element={<ClientsPage />} />
                <Route path={PathConstants.EDITOR_VIDEOS} element={<EditorVideosPage />} />
            </Route>
            <Route path='/login/callback' element={<LoginCallback loadingElement={<div>Loading...</div>} />} />
        </Routes>
    );
};

export default AppRoutes;