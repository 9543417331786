import { createSlice, current } from "@reduxjs/toolkit";
import { convertKMHtoMPH, convertMPHtoKMH } from "../../../utils/app-utils";
import lodash from "lodash";
import { addFirstServe } from "../EditorUtils";

const initialState = {
  originalMatch: null,
  match: null,
  activePoint: null,
  editMode: false,
  isPlaying: false,
  isPlayingFromEnd: false,
  replayTriggered: false,
  showPointsNavigation: true,
  currentPlayerTime: 0,
  isGlobalUpdateScore: false,
  lastScoresSequence: null,
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setIsPlayingFromEnd(state, action) {
      state.isPlayingFromEnd = action.payload;
    },
    setCurrentPlayerTime(state, action) {
      state.currentPlayerTime = action.payload;
    },
    toggleShowPointsNavigation(state, action) {
      state.showPointsNavigation = !state.showPointsNavigation;
    },

    triggerReplay(state, action) {
      state.isPlaying = true;
      state.replayTriggered = !state.replayTriggered;
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
      state.match = state.originalMatch;
      state.activePoint = state.originalMatch.points.find((point) => point.id === state.activePoint.id);
      // if state.editMode is false, then showPointsNavigation should be true
      if (!state.editMode)
        state.showPointsNavigation = true;
    },
    setMatch(state, action) {
      state.originalMatch = action.payload;
      state.match = action.payload;
      state.activePoint = action.payload.points[0];
    },
    setActivePoint(state, action) {
      state.isPlayingFromEnd = false;
      state.activePoint = action.payload;
      state.isPlaying = true;
    },
    setActivePointWithStep(state, action) {
      const currentIndex = state.match.points.findIndex(
        (point) => point.pointNumber === state.activePoint.pointNumber
      );
      let nextIndex = currentIndex + Number(action.payload.step);
      if (nextIndex >= state.match.points.length) {
        state.isPlaying = false;
        state.isPlayingFromEnd = false;
        return;
      }
      if (nextIndex < 0) nextIndex = 0;
      state.activePoint = state.match.points[nextIndex];
      state.isPlaying = true;
      state.isPlayingFromEnd = false;
      console.log(current(state.activePoint.shots));
      return state;
    },
    editMatch(state, action) {
      const newMatch = action.payload;

      if (action.payload.speedUnit != state.match.speedUnit) {
        newMatch.points = state.match.points.map((point) => {
          point.shots = point.shots.map((shot) => {
            if (shot.speed) {
              shot.speed = action.payload.speedUnit == 0 ? convertKMHtoMPH(shot.speed) : convertMPHtoKMH(shot.speed);
            }
            return shot;
          });
          return point;
        });
        state.activePoint = newMatch.points.find((point) => point.id === state.activePoint.id);
      }
      state.match = newMatch;
    },

    editActivePoint(state, action) {
      var currentPoint = state.originalMatch.points.find((point) => point.id === state.activePoint.id);
      action.payload.isUpdated = !currentPoint || currentPoint.isUpdated || !lodash.isEqual({ ...currentPoint, isUpdated: false, pointNumber: 0 }, { ...action.payload, isUpdated: false, pointNumber: 0 });
      state.match.points = state.match.points.map((point) =>
        point.id === action.payload.id ? action.payload : point
      );
      state.activePoint = action.payload;
    },
    removePoint(state, action) {
      state.match.points = state.match.points.filter(p => p.id != action.payload)

      for (let i = 0; i < state.match.points.length; i++) {
        state.match.points[i].pointNumber = i + 1;
      }

      if (state.activePoint.id === action.payload) {
        const activePointIndex = state.match.points.findIndex(
          (point) => point.id === state.activePoint.id
        );
        const nextIndex = activePointIndex - 1 >= 0 ? activePointIndex - 1 : 0;
        state.activePoint = state.match.points[nextIndex];
      }
      else {
        state.activePoint = state.match.points.find((point) => point.id === state.activePoint.id);
      }
    },
    addPoint(state, action) {
      let index = action.payload.index;
      const addedBy = action.payload.addedBy;
      const currentPoints = state.match.points;
      if (index === currentPoints.length)
        index = index - 1;
      const newPoints = [
        ...currentPoints.slice(0, index),
        { pointNumber: index + 1, id: Math.floor(Math.random() * 1000000), startTime: 0, endTime: 0, score: "0-0 0-0 00-00", isManuallyAdded: true, isUpdated: true, addedBy: addedBy, shots: [] },
        ...currentPoints.slice(index)
      ];

      addFirstServe(newPoints[index]);

      for (let i = index + 1; i < newPoints.length; i++) {
        newPoints[i].pointNumber = i + 1;
      }
      state.match.points = newPoints;
      state.activePoint = state.match.points.find((point) => point.id === state.activePoint.id);
    },
    resetPoint(state, action) {
      const pointNumber = state.activePoint.pointNumber;
      state.activePoint = state.originalMatch.points.find((point) => point.id === state.activePoint.id);
      state.activePoint.pointNumber = pointNumber;
      state.match.points = state.match.points.map((point) =>
        point.id === state.activePoint.id ? state.activePoint : point
      );
    },
    updatePoint(state, action) {
      state.originalMatch.points = state.originalMatch.points.map((point) =>
        point.id === action.payload.id ? action.payload : point
      );
    },
    updateMatch(state, action) {
      state.match = action.payload;
      state.activePoint = action.payload.points.find((point) => point.id === state.activePoint.id);
    },
    toggleGlobalEditScore(state, action) {
      state.isGlobalUpdateScore = !state.isGlobalUpdateScore;
    },
    setLastScoresSequence(state, action) {
      state.lastScoresSequence = action.payload;
    }
  },
});

export const {
  reset,
  setIsPlaying,
  setIsPlayingFromEnd,
  setCurrentPlayerTime,
  triggerReplay,
  setMatch,
  setActivePoint,
  setEditMode,
  setActivePointWithStep,
  editActivePoint,
  removePoint,
  addPoint,
  toggleShowPointsNavigation,
  editMatch,
  resetPoint,
  updatePoint,
  updateMatch,
  toggleGlobalEditScore,
  setLastScoresSequence
} = editorSlice.actions;

export const selectMatch = (state) => state.editor.match;

export const selectActivePoint = (state) => state.editor.activePoint;

export const selectIsPlaying = (state) => state.editor.isPlaying;
export const selectIsPlayingFromEnd = (state) => state.editor.isPlayingFromEnd;
export const selectReplay = (state) => state.editor.replayTriggered;
export const selectCurrentPlayerTime = (state) => state.editor.currentPlayerTime;

export const selectEditMode = (state) => state.editor.editMode;

export const selectShowPointsNavigation = (state) => state.editor.showPointsNavigation;
export const selectGlobalEditScore = (state) => state.editor.isGlobalUpdateScore;
export const selectLastScoresSequence = (state) => state.editor.lastScoresSequence;

export default editorSlice.reducer;