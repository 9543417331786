import { convertTimeStringToSeconds } from '../../utils/app-utils';

const deuceCourtScores = ["0000", "1515", "3000", "0030", "3030", "1540", "4015", "4040"];

const pressureScoresAd = ["0030", "0040", "1530", "1540", "3030", "3040", "4040", "40AD"];
const pressureScoresNoAd = ["0030", "0040", "1530", "1540", "3030", "3040", "4030", "4040"];
const breakScoresAd = ["0040", "1540", "3040", "40AD"];
const breakScoresNoAd = ["0040", "1540", "3040", "4040"];


import { serviceCourtOptions, serviceLocationTypeOptions, shotLocationTypeOptions } from './options';

const emptyShot = {
    shotId: 0,
    matchPointId: 0,
    playerId: 0,
    shotOrderId: 0,
    number: 0,
    isAce: false,
}

function addFirstServe(newPoint) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === 0) === -1) {
        let shots = [...newPoint.shots];
        shots.splice(0, 0, {
            ...emptyShot,
            shotOrderId: 0,
            shotTypeId: 2,
            strokeTypeId: 0,
            verticalPlayerPositionId: 0,
            horizontalPlayerPositionId: 2,
            shotMovementTypeId: 2,
            number: 1,
        });

        newPoint.shots = shots;
    }
}

function addSecondServe(newPoint) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === 1) === -1) {
        let shots = [...newPoint.shots];
        shots.splice(1, 0, {
            ...emptyShot,
            shotOrderId: 1,
            shotTypeId: 2,
            strokeTypeId: 0,
            verticalPlayerPositionId: 0,
            horizontalPlayerPositionId: 2,
            shotMovementTypeId: 2,
            number: 1,
        });

        newPoint.shots = shots;
    }
}

function addReturn(newPoint) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === 2) === -1) {
        let shots = [...newPoint.shots];
        shots.push({
            ...emptyShot,
            shotOrderId: 2,
            number: 2,
        });
        newPoint.shots = shots;
    }
}

function isEmptyField(value, options) {
    return value == null || value == undefined || value === '' || (options && options.length > 0 && options.findIndex((o) => o.value === value) === -1);
}

function transformMatchPoint(point, match) {
    point.startTime = convertTimeStringToSeconds(point.startTime, true);
    point.endTime = convertTimeStringToSeconds(point.endTime, false);
    let firstServe = { ...point.shots.find((s) => s.shotOrderId === 0) };
    // 2nd serve point or double fault
    if (point.serveTypeId != 0 && firstServe.out == null) {
        firstServe.out = true;
        firstServe.net = false;

        let secondServe = point.shots.find((s) => s.shotOrderId === 1);
        //double fault
        if (point.serveTypeId === 2) {
            secondServe.out = true;
            secondServe.net = false;
        } else {
            secondServe.out = false;
            secondServe.net = false;
        }
        point.shots = point.shots.map((s) => s.shotOrderId === 1 ? secondServe : s);
    } else if (firstServe.out == null) {
        firstServe.out = false;
        firstServe.net = false;
    }

    //return point
    if (!point.isAce && point.serveTypeId != 2) {
        let returnShot = point.shots.find((s) => s.shotOrderId === 2);
        if (point.rallyLength == 2 && point.winner == point.server && returnShot.out == null) {
            returnShot.out = true;
            returnShot.net = false;
        }
        else if (returnShot.out == null) {
            returnShot.out = false;
            returnShot.net = false;
        }
        point.shots = point.shots.map((s) => s.shotOrderId === 2 ? returnShot : s);
    }
    point.shots = point.shots.map((s) => s.shotOrderId === 0 ? firstServe : s);

    if (point.server === point.returner) {
        point.returner = point.server === match.player1 ? match.player2 : match.player1;
    }
    return point;
}

function getPointMissingFields(point) {
    const firstServeShot = point.shots.find((s) => s.shotOrderId === 0);
    const secondServeShot = point.shots.find((s) => s.shotOrderId === 1);
    const returnShot = point.shots.find((s) => s.shotOrderId === 2);
    let missingFields = [];

    //start time is required
    if (isEmptyField(point.startTime)) {
        missingFields.push('Start Time is required.');
    }
    //end time is required
    if (isEmptyField(point.endTime)) {
        missingFields.push('End Time is required.');
    }
    //score is required
    if (isEmptyField(point.score)) {
        missingFields.push('Score is required.');
    }
    //server is required
    if (isEmptyField(point.server)) {
        missingFields.push('Server is required.');
    }
    //service court is required
    if (isEmptyField(point.serviceCourt, serviceCourtOptions)) {
        missingFields.push('Service Court is required.');
    }
    //winner is required
    if (isEmptyField(point.winner)) {
        missingFields.push('Winner is required.');
    }
    //first serve Location is required
    if (firstServeShot && isEmptyField(firstServeShot.serviceLocationId, serviceLocationTypeOptions)) {
        missingFields.push("First Serve Location is required.");
    }
    //second serve Location is required
    if (secondServeShot && isEmptyField(secondServeShot.serviceLocationId, serviceLocationTypeOptions)) {
        missingFields.push("Second Serve Location is required.");
    }
    //return errors: return location is required
    if (returnShot && isEmptyField(returnShot.shotLocationId, shotLocationTypeOptions)) {
        missingFields.push('Return Location is required.');
    }
    return missingFields;
};

function getPointErrors(point) {
    let errors = [];

    //point start time less than endTime
    if (point.startTime >= point.endTime) {
        errors.push('Start Time should be less than End Time.');
    }
    //Rally length equal to 0 and not double fault
    if (point.serveTypeId !== 2 && point.rallyLength == 0) {
        errors.push('Rally Length is 0 and point is not double fault.');
    }
    //rally length is 1 and not ace
    if (!point.isAce && point.rallyLength === 1) {
        errors.push('Rally Length is 1 and point is not ace.');
    }
    //ace and rally length not equal to 1
    if (point.isAce && point.rallyLength != 1) {
        errors.push('Ace Point. Rally Length should be 1.');
    }
    //double fault and rally length not equal to 0
    if (point.serveTypeId === 2 && point.rallyLength != 0) {
        errors.push('Double Fault Point. Rally Length should be 0.');
    }
    //ace and winner is returner
    if (point.isAce && point.winner === point.returner) {
        errors.push('Ace Point. Winner cannot be Returner.');
    }
    //double fault and winner is server
    if (point.serveTypeId === 2 && point.winner === point.server) {
        errors.push('Double Fault Point. Winner cannot be Server.');
    }
    return errors;
}

const getPointProgress = (point) => {
    const rulesCount = 16;
    const pointErrors = getPointErrors(point);
    const missingFields = getPointMissingFields(point);
    const progress = Math.round((rulesCount - pointErrors.length - missingFields.length) / rulesCount * 100);
    return progress;
}

const getMatchProgress = (points) => {
    var totalProgress = points.reduce((acc, point) => acc + getPointProgress(point), 0);
    return Math.round(totalProgress / points.length);
}

const updatePointScore = (point, updatedScore, match) => {
    const updatedPoint = { ...point };
    const [matchScore, setScore, gameScore] = updatedScore.split(' ');

    // Update match, set, and game scores
    const [matchScore1, matchScore2] = matchScore.split('-');
    const [setScore1, setScore2] = setScore.split('-');
    const playersScores = gameScore.split('-');

    updatedPoint.matchScore1 = Number(matchScore1);
    updatedPoint.matchScore2 = Number(matchScore2);
    updatedPoint.matchScore = matchScore;
    updatedPoint.setScore1 = Number(setScore1);
    updatedPoint.setScore2 = Number(setScore2);
    updatedPoint.setScore = setScore;
    updatedPoint.gameScore = gameScore.replace('-', '');
    updatedPoint.score = updatedScore;

    // Determine the server's score
    const isTiebreakGame = isTiebreak(matchScore, setScore, match.matchFormat);
    const serverFirstScore = point.server === match.player1 ? `${playersScores[0]}${playersScores[1]}` : `${playersScores[1]}${playersScores[0]}`;;
    const isAdvantage = match.typeOfScoring === 1;

    // Update service court (Ad or Deuce)
    const isTiebreakDeuce = isTiebreakGame && (Number(playersScores[0]) + Number(playersScores[1])) % 2 === 0;
    updatedPoint.serviceCourt = deuceCourtScores.includes(serverFirstScore) || isTiebreakDeuce ? 1 : 0;

    // Update pressure point flag
    const pressureScores = isAdvantage ? pressureScoresAd : pressureScoresNoAd;
    updatedPoint.isPressurePoint = pressureScores.includes(serverFirstScore.toUpperCase()) || isTiebreakGame;

    // Update break point flag
    const breakScores = isAdvantage ? breakScoresAd : breakScoresNoAd;
    updatedPoint.isBreakPoint = !isTiebreakGame && breakScores.includes(serverFirstScore.toUpperCase());

    return updatedPoint;
}

const isTiebreak = (matchScore, setScore, matchFormat) => {
    switch (matchFormat) {
        case "SingleSet":
        case "BestOfThreeTiebreakSets":
        case "BestOfFiveTiebreakSets":
            return setScore === "6-6";
        case "BestOfThreeTenPointFinalSetTiebreak":
            return setScore === "6-6" || matchScore === "1-1"
        case "BestOfFiveTenPointFinalSetTiebreak":
            return setScore === "6-6" || matchScore === "2-2"
        case "BestOfThreeFastFourSets":
            return setScore === "3-3" || matchScore === "1-1"
        case "EightGameProSet":
            return setScore === "7-7"
    }
}

export {
    addFirstServe,
    addSecondServe,
    addReturn,
    isEmptyField,
    transformMatchPoint,
    updatePointScore,
    getPointErrors,
    getPointMissingFields,
    getPointProgress,
    getMatchProgress
}