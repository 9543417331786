import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const cvSlice = createApi({
    reducerPath: "cvapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/cv`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCVDashboard: builder.query({
            query: ({ pageNumber, pageSize, searchWords, status, movedToVideoApp, orderByDate, orderByLastUpdate, source }) =>
                `/dashboard?pageNumber=${pageNumber}&pageSize=${pageSize}&searchWords=${searchWords}&status=${status}&movedToVideoApp=${movedToVideoApp}&orderByDate=${orderByDate}&orderByLastUpdate=${orderByLastUpdate}&source=${source}`,
            transformResponse: (response) => {
                response.items = response.items.map((item) => {
                    return {
                        ...item,
                        selectedSnapshot: ''
                    }
                });
                return response;
            },
        }),
        getEditorVideos: builder.query({
            query: ({ pageNumber, pageSize, searchWords, status, assignee, assignment, typeOfCoding }) =>
                `/editor?pageNumber=${pageNumber}&pageSize=${pageSize}&searchWords=${searchWords}&status=${status}&assignee=${assignee}&assignment=${assignment}&typeOfCoding=${encodeURIComponent(typeOfCoding)}`,
        }),
        terminateCVProcess: builder.mutation({
            query: (videoId) => ({
                url: `/terminate/${videoId}`,
                method: "POST",
            }),
        }),
        createSnapshot: builder.mutation({
            query: (videoId) => ({
                url: `/snapshot/${videoId}`,
                method: "POST",
            }),
        }),
        addComment: builder.mutation({
            query: ({ videoProcessId, comment }) => ({
                url: `/comment/${videoProcessId}`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: `${JSON.stringify(comment)}`,
            }),
        }),
        getMetrics: builder.query({
            query: ({ startDate, endDate, source }) => `/metrics?startDate=${startDate}&endDate=${endDate}&source=${source}`,
        }),
    }),
});

export const {
    useGetCVDashboardQuery,
    useLazyGetEditorVideosQuery,
    useTerminateCVProcessMutation,
    useCreateSnapshotMutation,
    useAddCommentMutation,
    useGetMetricsQuery,
} = cvSlice;

export default cvSlice.reducer;
