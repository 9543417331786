import { useState } from "react";
import { useGetMetricsQuery } from "../api/cvSlice";
import Spinner from "../../UI/Spinner";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const CVMetrics = () => {
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    const [startDate, setStartDate] = useState(oneWeekAgo);
    const [endDate, setEndDate] = useState(today);
    const [source, setSource] = useState('');
    const { data, isError, isLoading, isFetching, refetch } = useGetMetricsQuery({ startDate: startDate.toISOString(), endDate: endDate.toISOString(), source: source });

    return (
        <>
            <button className="btn btn-dark btn-border-light" data-toggle="modal" data-target="#CVMetrics">CV Metrics</button>
            <div className="modal fade" id="CVMetrics" tabIndex="-1" aria-labelledby="CVMetricsLabel" aria-hidden="true">
                <div style={{ minWidth: "50%" }} className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CVMetricsLabel">CV Metrics</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-around mb-2">
                                <div className="d-flex flex-column align-items-center" onChange={(e) => setSource(e.target.value)}>
                                    <h5>Source</h5>
                                    <select value={source} className="form-control">
                                        <option value=''>All</option>
                                        <option value='ttv'>Tennis TV</option>
                                        <option value='wtv'>WTA TV</option>
                                        <option value='ctv'>Challenger TV</option>
                                        <option value='recording-team'>Recording Team</option>
                                        <option value='wtt'>ITF</option>
                                        <option value='tc'>Tennis Channel</option>
                                        <option value='espn'>ESPN</option>
                                        <option value='dropbox'>Dropbox</option>
                                        <option value='img'>IMG</option>
                                    </select>
                                </div>
                                <ThemeProvider theme={darkTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="Start Date"
                                                value={dayjs(startDate)}
                                                onChange={(newValue) => setStartDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="End Date"
                                                value={dayjs(endDate)}
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </ThemeProvider>
                            </div>
                            {(isLoading || isFetching) && <Spinner />}
                            {isError && <div className="alert alert-danger" role="alert">Unable to retrieve metrics.</div>}
                            {data && <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Metric</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Average Processing Time</td>
                                        <td>{data.averageProcessingTimeMinutes.toFixed(2)} minutes</td>
                                    </tr>
                                    <tr>
                                        <td>Failed Videos</td>
                                        <td>{data.failedVideos.numerator}/{data.failedVideos.denominator} ({data.failedVideos.percentage}%)</td>
                                    </tr>
                                    <tr>
                                        <td>Successful Videos</td>
                                        <td>{data.successfulVideos.numerator}/{data.successfulVideos.denominator} ({data.successfulVideos.percentage}%)</td>
                                    </tr>
                                    <tr>
                                        <td>Processed Multiple Times Videos</td>
                                        <td>{data.videosProcessedMultipleTimes.numerator}/{data.videosProcessedMultipleTimes.denominator} ({data.videosProcessedMultipleTimes.percentage}%)</td>
                                    </tr>
                                    <tr>
                                        <td>Average Needs Review Points</td>
                                        <td>{data.averageNeedsReviewPoints.toFixed(2)} per match ({data.percentageNeedsReviewPoints.toFixed(2)}%)</td>
                                    </tr>
                                    <tr>
                                        <td>Average Edited Points</td>
                                        <td>{data.averageEditedPoints.toFixed(2)} per match  ({data.percentageEditedPoints.toFixed(2)}%)</td>
                                    </tr>
                                    <tr>
                                        <td>Average Added Points</td>
                                        <td>{data.averageAddedPoints.toFixed(2)} per match</td>
                                    </tr>
                                </tbody>
                            </table>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" onClick={refetch} disabled={isLoading || isFetching}>Refresh</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CVMetrics;
