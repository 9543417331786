import { useGetLocalEventInstancesQuery, useGetAllLocalPlayersQuery } from "../../../features/api/completeMatchesSlice";
import { useCreateAnalystCardReportMutation } from "../../../features/api/reportExecutionSlice";
import { useEffect, useState } from "react";
import Select from "react-select";
import SourceSelection from "./SourceSelection";
import Spinner from "../../../UI/Spinner";
import { analystInitialInputs, initialSourceModel } from "../reportsOptions";
import useDropboxPath from "../../../custom-hooks/useDropboxPath";
import useShowToastr from "../../../custom-hooks/useShowToastr";

const NewAnalystCardReport = ({ reportExecutionId, stdReports, heReports, editingReport, targetLocalName, isUserReport }) => {
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [playerOptions, setPlayerOptions] = useState([]);

    const [serveModel, setServeModel] = useState({ ...initialSourceModel, source: 1 });
    const [returnModel, setReturnModel] = useState({ ...initialSourceModel, source: 2 });
    const [gsModel, setGSModel] = useState({ ...initialSourceModel, source: 3 });
    const [sources, setSources] = useState({ serveSource: 1, returnSource: 1, gsSource: 1 });

    const [formInputs, setFormInputs] = useState(analystInitialInputs);

    const [createAnalystCardReport, { isLoading: loadingReportCreation, isSuccess: createdSuccessfully, isError: creationError }] = useCreateAnalystCardReportMutation();

    const { data: tournaments } = useGetLocalEventInstancesQuery();
    const { data: allPlayers } = useGetAllLocalPlayersQuery();
    const isValid = formInputs.targetName != "" && serveModel.isValid && (sources.returnSource != 2 || returnModel.isValid) && (sources.gsSource != 3 || gsModel.isValid);

    const { userSettings, setUserSettings } = useDropboxPath({ isUserReport, container: "analyst-dropbox-container", setFormInputs });

    useShowToastr({ isSuccess: createdSuccessfully, isError: creationError, successMessage: "Created Successfully!", errorMessage: "Failed to create!", title: "Create New Report", modalId: "AddNewAnalystCardReportModal" });

    useEffect(() => {
        if (editingReport) {
            const reportInputs = { ...editingReport.reportInputs };
            setFormInputs({
                targetName: reportInputs.targetName,
                clientName: reportInputs.clientName,
                tournament: reportInputs.tournament,
                isClientLeftHanded: reportInputs.isClientLeftHanded,
                fileNameSuffix: reportInputs.fileNameSuffix
            });

            const serveModel = updateModelSources(reportInputs.serveSource);
            const returnModel = updateModelSources(reportInputs.returnSource);
            const gsModel = updateModelSources(reportInputs.gsSource);

            setServeModel({ ...serveModel, source: 1 });
            setReturnModel({ ...returnModel, source: 2 });
            setGSModel({ ...gsModel, source: 3 });
            setSources(reportInputs.sectionSources);
        } else {
            const newFormInputs = { ...analystInitialInputs, dropboxOutputDir: userSettings?.defaultDropboxPath };
            const targetPlayer = allPlayers?.find(p => p.localName === targetLocalName);

            if (targetPlayer) {
                newFormInputs.targetName = targetPlayer.localName;
            }
            setFormInputs(newFormInputs);
            setServeModel({ ...initialSourceModel, source: 1 });
            setReturnModel({ ...initialSourceModel, source: 2 });
            setGSModel({ ...initialSourceModel, source: 3 });
            setSources({ serveSource: 1, returnSource: 1, gsSource: 1 });
        }
    }, [editingReport]);

    useEffect(() => {
        if (tournaments) {
            setTournamentOptions([{ label: "None", value: "" }, ...tournaments.map((tournament) => ({
                label: `${tournament.name} - ${tournament.category}`,
                value: tournament.name
            }))]);
        }
    }, [tournaments]);

    useEffect(() => {
        if (allPlayers) {
            const targetPlayer = allPlayers.find(p => p.localName === targetLocalName);

            if (targetPlayer) {
                setFormInputs({ ...formInputs, targetName: targetPlayer.localName });
            } else {
                setFormInputs({ ...formInputs, targetName: "" });
            }

            setPlayerOptions(allPlayers
                .map((player) => ({
                    label: player.name,
                    value: player.localName
                })));
        }
    }, [allPlayers, targetLocalName])

    const handleCreateReport = async () => {
        const payload = {
            ...formInputs,
            serveSource: serveModel,
            returnSource: sources.returnSource == 2 ? returnModel : serveModel,
            gsSource: sources.gsSource == 3 ? gsModel : sources.gsSource == 2 ? returnModel : serveModel,
            sectionSources: sources,
            blobOutputDir: `report-automation/${reportExecutionId}/analyst-report`
        };

        await createAnalystCardReport({ reportInputsModel: payload, reportExecutionId: reportExecutionId, reportId: editingReport?.reportId });
    }

    const sourceModelChanged = (model) => {
        switch (model.source) {
            case 1:
                setServeModel(model);
                break;
            case 2:
                setReturnModel(model);
                break;
            case 3:
                setGSModel(model);
                break;
        }
    };

    const updateModelSources = (model) => {
        const newModel = {
            jsonFilePath: checkStdReportExists(model.jsonFilePath),
            jsonFilePath2: checkStdReportExists(model.jsonFilePath2),
            heBlobDir: checkHEReportExists(model.heBlobDir),
        }
        newModel.isValid = newModel.jsonFilePath != "";
        return newModel;
    }

    const checkStdReportExists = (path) => {
        const exists = stdReports.some(report => report.resources.some(resource => resource.path === path));
        if (exists)
            return path;
        return "";
    }

    const checkHEReportExists = (path) => {
        const exists = heReports.some(report => report.resources.some(resource => resource.path === path));
        if (exists)
            return path;
        return "";
    }
    return (
        <div
            className="modal fade"
            id="AddNewAnalystCardReportModal"
            role="dialog"
            aria-labelledby="AddNewAnalystCardReportModalLabel"
            aria-hidden="true"
        >
            <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewAnalystCardReportModalLabel">
                            {editingReport ? "Edit Analyst Card Report" : "Create New Analyst Card Report"}
                        </h3>
                    </div>
                    <div style={{ height: "75vh" }} className="modal-body scrollbar perfect-scrollbar">
                        <div className="container d-flex flex-column mb-2">
                            <div className="form-group">
                                <label htmlFor="targetPlayer">Target Player</label>
                                {playerOptions.length === 0 ? <Spinner /> :
                                    <div style={{ width: "30%" }} className="mb-3">
                                        <Select
                                            className="dark-theme"
                                            value={formInputs.targetName === "" ? null : playerOptions.find(p => p.value === formInputs.targetName)}
                                            options={playerOptions}
                                            onChange={(selectedOption) => setFormInputs({ ...formInputs, targetName: selectedOption.value })} />
                                    </div>
                                }

                                <label htmlFor="opponent">Client Name</label>
                                <div className="row align-items-center mb-3 ml-0">
                                    {playerOptions.length === 0 ? <Spinner /> : <div style={{ width: "30%" }} className="mr-3">
                                        <Select
                                            className="dark-theme"
                                            value={formInputs.clientName === "" ? null : playerOptions.find(p => p.value === formInputs.clientName)}
                                            options={playerOptions}
                                            onChange={(selectedOption) => setFormInputs({ ...formInputs, clientName: selectedOption.value })} />
                                    </div>
                                    }
                                    <input
                                        type="checkbox"
                                        id="isClientLeftHanded"
                                        checked={formInputs.isClientLeftHanded}
                                        onChange={(e) => setFormInputs({ ...formInputs, isClientLeftHanded: e.target.checked })} />
                                    <label className="ml-2 mb-0" htmlFor="isClientLeftHanded">Is Client Left-Handed</label>
                                </div>

                                <label htmlFor="fileNameSuffix">File Name Suffix</label>
                                <input style={{ width: "30%" }} type="text" className="form-control" id="fileNameSuffix" value={formInputs.fileNameSuffix} onChange={(e) => setFormInputs({ ...formInputs, fileNameSuffix: e.target.value })} />

                                <div style={{ width: "30%" }} className="d-flex flex-column mb-2 mt-2">
                                    <label htmlFor="tournament">Tournament</label>
                                    <Select
                                        className="dark-theme"
                                        closeMenuOnSelect={false}
                                        options={tournamentOptions}
                                        value={formInputs.tournament === "" ? null : tournamentOptions.find(t => t.value === formInputs.tournament)}
                                        onChange={(selectedOption) => setFormInputs({ ...formInputs, tournament: selectedOption.value })} />
                                </div>
                                <ul className="nav nav-pills mt-3" id="pill-myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#serve-source-selection" role="tab" aria-controls="serve-source-selection" aria-selected="true">Serve</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${sources.returnSource != 2 ? "disabled" : ""}`} data-toggle="tab" href="#return-source-selection" role="tab" aria-controls="return-source-selection" aria-selected="false" aria-disabled={sources.returnSource != 2}>Return</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${sources.gsSource != 3 ? "disabled" : ""}`} data-toggle="tab" href="#gs-source-selection" role="tab" aria-controls="gs-source-selection" aria-selected="false" aria-disabled={sources.gsSource != 3}>GS</a>
                                    </li>
                                </ul>
                                <div className="tab-content p-3" >
                                    <div id="serve-source-selection" className="tab-pane fade show active">
                                        <SourceSelection
                                            stdReports={stdReports}
                                            heReports={heReports}
                                            model={serveModel}
                                            setModel={sourceModelChanged}
                                            sources={sources}
                                            setSources={setSources} />
                                    </div>
                                    <div id="return-source-selection" className="tab-pane fade">
                                        <SourceSelection
                                            stdReports={stdReports}
                                            heReports={heReports}
                                            model={returnModel}
                                            setModel={sourceModelChanged}
                                            sources={sources}
                                            setSources={setSources} />
                                    </div>
                                    <div id="gs-source-selection" className="tab-pane fade">
                                        <SourceSelection
                                            stdReports={stdReports}
                                            heReports={heReports}
                                            model={gsModel}
                                            setModel={sourceModelChanged}
                                            sources={sources}
                                            setSources={setSources} />
                                    </div>
                                </div>
                                {
                                    isUserReport &&
                                    <div className="mt-3">
                                        <label className="fs-0 mb-0 mr-2">Upload to dropbox </label>
                                        <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadPlayerCardByDefault: !prev.uploadPlayerCardByDefault }))}>
                                            <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadPlayerCardByDefault}></input>
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                }
                                {isUserReport && userSettings.uploadPlayerCardByDefault && <div style={{ width: "70%" }} className="d-flex row flex-nowrap mt-3 align-items-center ml-0">
                                    <div id="analyst-dropbox-container" />
                                    <input style={{ width: "70%" }} readOnly value={formInputs.dropboxOutputDir} className="form-control ml-2 p-0 pl-2" />
                                </div>}
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <button className="btn btn-warning btn-md" type="button"
                            onClick={handleCreateReport}
                            disabled={loadingReportCreation || !isValid}>
                            {!editingReport ? (loadingReportCreation ? "Creating..." : "Create Report") : (loadingReportCreation ? "Editing..." : "Edit Report")}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NewAnalystCardReport;