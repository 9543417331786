import { useReducer, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsCoder, selectUser } from "../auth/authSlice";
import { useGetCodersListQuery } from "../api/oktaSlice";
import { reactSelectOptions } from "../../utils/app-utils";
import { useLazyGetEditorVideosQuery } from "../api/cvSlice";
import { useLazyGetEditorVideoQuery } from "../api/collectedVideosSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";
import ResponsivePagination from 'react-responsive-pagination';
import Select from 'react-select';
import VideosList from "./VideosList";
import VideoDetails from "./VideoDetails";

const opsEditorVideosReducer = (state, action) => {
    switch (action.type) {
        case "SET_SELECTED_VIDEO":
            return { ...state, selectedVideo: action.value };
        case 'SET_SEARCH_TEXT':
            return { ...state, searchText: action.value, currentPage: 1 };
        case 'SET_STATUS':
            return { ...state, status: action.value, currentPage: 1 };
        case 'SET_ASSIGNEE':
            return { ...state, assignee: action.value, currentPage: 1 };
        case 'SET_ASSIGNMENT':
            return { ...state, assignment: action.value, currentPage: 1 };
        case 'SET_TYPE_OF_CODING':
            return { ...state, typeOfCoding: action.value, currentPage: 1 };
        case 'SET_CURRENT_PAGE':
            return { ...state, currentPage: action.value };
        case 'SET_EDITOR_ITEMS':
            return { ...state, editorItems: action.value.items, totalCount: action.value.totalCount };
        case 'SET_SELECTED_VIDEO_ID':
            return { ...state, selectedVideoId: action.value };
        default:
            return state;
    }
}

const initialInputs = {
    searchText: '',
    status: '',
    assignee: '',
    assignment: '',
    typeOfCoding: '',
    currentPage: 1,
    editorItems: [],
    selectedVideoId: null,
    totalCount: 0,
};

const EditorVideosPage = () => {
    const [timeoutHandle, setTimeoutHandle] = useState(null);
    const [state, dispatch] = useReducer(opsEditorVideosReducer, initialInputs);

    const { selectedVideo, searchText, status, assignee, assignment, typeOfCoding, currentPage, editorItems, selectedVideoId, totalCount } = state;

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isCoder = useSelector(selectIsCoder);

    const { data: codersList, isLoading: loadingCoders } = useGetCodersListQuery();
    const [getVideos, { isLoading: loadingVideos, isFetching: fetchingVideos }] = useLazyGetEditorVideosQuery();
    const [getEditorVideo, { isLoading: loadingSelectedVideo, isFetching: fetchingSelectedVideo }] = useLazyGetEditorVideoQuery();

    const fetchVideos = async () => {
        var response = await getVideos({ pageNumber: currentPage, pageSize: 10, searchWords: searchText.length > 0 ? searchText.split(' ').concat(',') : "", status, assignee, assignment, typeOfCoding });
        if (response.data) {
            dispatch({ type: 'SET_EDITOR_ITEMS', value: response.data });
        }
    }

    useEffect(() => {
        if (!isAdmin && !assignee) return;
        fetchVideos();
    }, [searchText, status, assignee, currentPage, isAdmin, assignment, typeOfCoding]);

    useEffect(() => {
        if (user && !isAdmin)
            dispatch({ type: 'SET_ASSIGNEE', value: user?.preferred_username ?? null });
    }, [isAdmin, user]);

    const countPages = totalCount && !loadingVideos ? Math.ceil(totalCount / 10) : 0;

    const codersOptions = codersList ? [
        { label: 'None', value: '' },
        ...codersList.map(coder => ({ label: coder.name, value: coder.name }))
    ] : null;

    const searchTextChangedHandler = (event) => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(setTimeout(() => {
            dispatch({ type: 'SET_SEARCH_TEXT', value: event.target.value });
        }, 1000));
    }

    const statusChangedHandler = (event) => {
        dispatch({ type: 'SET_STATUS', value: event.target.value });
    }

    const assignmentChangedHandler = (event) => {
        dispatch({ type: 'SET_ASSIGNMENT', value: event.target.value });
    }

    const typeOfCodingChangedHandler = (event) => {
        dispatch({ type: 'SET_TYPE_OF_CODING', value: event.target.value });
    }

    const selectedVideoChangedHandler = async (selectedVideoId) => {
        dispatch({ type: 'SET_SELECTED_VIDEO_ID', value: selectedVideoId });
        var response = await getEditorVideo(selectedVideoId);
        dispatch({ type: 'SET_SELECTED_VIDEO', value: response.data });
    }

    if (!user) return <Spinner />;
    else if (!isAdmin && !isCoder) return <NotAuthorizedPage />;
    return (
        <div className="d-flex flex-column ">
            <div className="d-flex row justify-content-center mb-3 w-100">
                <div className="d-flex flex-column align-items-center mr-5">
                    <h5>Title</h5>
                    <input type="text" className="form-control" placeholder="Search" onChange={searchTextChangedHandler} />
                </div>
                <div className="d-flex flex-column align-items-center mr-5">
                    <h5>Status</h5>
                    <select value={status} className="form-control" onChange={statusChangedHandler}>
                        <option value={""}>All</option>
                        {/* <option value={"To Do"}>To Do</option> */}
                        <option value={"In Progress"}>In Progress</option>
                        <option value={"In Review"}>In Review</option>
                        <option value={"Done"}>Done</option>
                    </select>
                </div>
                {isAdmin &&
                    <>
                        <div className="d-flex flex-column align-items-center mr-5">
                            <h5>Assignment</h5>
                            <select value={assignment} className="form-control" onChange={assignmentChangedHandler}>
                                <option value={''}>All</option>
                                <option value={false}>Unassigned</option>
                                <option value={true}>Assigned</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column align-items-center mr-5">
                            <h5>Type Of Coding</h5>
                            <select value={typeOfCoding} className="form-control" onChange={typeOfCodingChangedHandler}>
                                <option value={''}>All</option>
                                <option value={"Trimming"}>Trimming</option>
                                <option value={"Serve & Return"}>Serve & Return</option>
                                <option value={"GS"}>GS</option>
                                <option value={"Full"}>Full</option>
                            </select>
                        </div>
                        <div style={{ width: "15rem" }} className="d-flex flex-column align-items-center mr-5">
                            <h5>Assignee</h5>
                            {loadingCoders && <Spinner />}
                            {codersOptions &&
                                <Select
                                    className="w-100 dark-theme"
                                    styles={reactSelectOptions}
                                    options={codersOptions}
                                    placeholder="None"
                                    value={codersOptions.find(coder => coder.value === assignee)}
                                    onChange={(selectedOption) => dispatch({ type: 'SET_ASSIGNEE', value: selectedOption.value })}
                                />
                            }
                        </div>
                    </>
                }
            </div>
            <div className="row flex-nowrap">
                <div style={{ width: "30%" }}>
                    <VideosList videos={editorItems} count={!loadingVideos ? totalCount : 0} isLoading={loadingVideos || fetchingVideos} selectedVideoId={selectedVideoId} onSelectedVideoChange={selectedVideoChangedHandler} />

                    <div className=" my-3">
                        <ResponsivePagination
                            current={currentPage}
                            total={countPages}
                            onPageChange={(page) => dispatch({ type: 'SET_CURRENT_PAGE', value: page })}
                            previousLabel="Previous"
                            nextLabel="Next"
                        />
                    </div>
                </div>
                < div style={{ flexGrow: 1 }} className="mx-2">
                    {(loadingSelectedVideo || fetchingSelectedVideo) && <div className="w-75"> <Spinner /></div>}

                    {!loadingSelectedVideo && !fetchingSelectedVideo && selectedVideo &&
                        <VideoDetails video={selectedVideo} refetchVideos={fetchVideos} setSelectedVideo={(selectedVideo) => dispatch({ type: "SET_SELECTED_VIDEO", value: selectedVideo })} codersOptions={codersOptions} />
                    }
                </div>
            </div>
        </div >
    );
};

export default EditorVideosPage;